import React, { ReactNode } from 'react';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../src/theme';
import { AuthProvider } from '../../src/components/AuthProvider';
import Footer from '../../src/components/Footer/Footer';
import Header from '../../src/components/Header/Header';

type LayoutProps = {
  children: ReactNode;
  location: {
    pathname: string;
  };
};

const TopLayout: React.FC<LayoutProps> = (props) => {
  const currentPath = props.location?.pathname || '';
  const isConverterPage = currentPath === '/converter/';

  return (
    <>
      <Helmet>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta name="apple-itunes-app" content="app-id=6503993314" />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap"
          rel="stylesheet"
        />
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src={process.env.GATSBY_HUBSPOT_CHAT}
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <AuthProvider navigate={navigate}>
          {!isConverterPage && <Header siteTitle="CurrencyBird" />}
          {props.children}
          {/* Renderiza el Footer solo si no es la página "/converter" */}
          {!isConverterPage && <Footer />}
        </AuthProvider>
      </ThemeProvider>
    </>
  );
};

export default TopLayout;
